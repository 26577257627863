exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-basement-waterproofing-js": () => import("./../../../src/pages/services/basement-waterproofing.js" /* webpackChunkName: "component---src-pages-services-basement-waterproofing-js" */),
  "component---src-pages-services-bathroom-waterproofing-js": () => import("./../../../src/pages/services/bathroom-waterproofing.js" /* webpackChunkName: "component---src-pages-services-bathroom-waterproofing-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-leaking-balcony-repair-js": () => import("./../../../src/pages/services/leaking-balcony-repair.js" /* webpackChunkName: "component---src-pages-services-leaking-balcony-repair-js" */),
  "component---src-pages-services-rooftop-waterproofing-js": () => import("./../../../src/pages/services/rooftop-waterproofing.js" /* webpackChunkName: "component---src-pages-services-rooftop-waterproofing-js" */),
  "component---src-pages-services-shower-repair-js": () => import("./../../../src/pages/services/shower-repair.js" /* webpackChunkName: "component---src-pages-services-shower-repair-js" */),
  "component---src-pages-services-sprayed-systems-liquid-rubber-js": () => import("./../../../src/pages/services/sprayed-systems-liquid-rubber.js" /* webpackChunkName: "component---src-pages-services-sprayed-systems-liquid-rubber-js" */),
  "component---src-pages-services-waterproofing-retaining-wall-js": () => import("./../../../src/pages/services/waterproofing-retaining-wall.js" /* webpackChunkName: "component---src-pages-services-waterproofing-retaining-wall-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

